<template>
    <form
        v-show="!(inNavigation && isSearchPage)"
        class="search-form"
        :class="{'search-form--navigation' : inNavigation}"
        @submit.prevent="doSearch"
    >
        <BaseInput
            :model-value="searchQuery"
            name="search"
            :placeholder="placeholder"
            type="search"
            :clearable="false"
            :wrapper-attrs="{ class: inputClass }"
            @update:model-value="searchQuery = $event.target.value"
        >
            <template #after>
                <button
                    key="search"
                    type="button"
                    class="button--clean"
                    aria-label="Search"
                    @click.prevent="doSearch"
                >
                    <BaseIcon
                        icon="search"
                        data-icon="search"
                    />
                </button>
            </template>
        </BaseInput>
    </form>
</template>

<script setup>
const SEARCH_PATH = '/search';
const props = defineProps({
    inNavigation: {
        type: Boolean,
        default: false
    },
    placeholder: {
        type: String,
        default: 'Search',
        required: false
    }
});
const searchQuery = ref('');
const route = useRoute();
const router = useRouter();

const searchParam = computed(() => route.query.q);
const isSearchPage = computed(() => route.path === SEARCH_PATH);
const inputClass = computed(() => props.inNavigation ? 'dn-input--rounded-secondary' : 'dn-input--rounded');

watch(searchParam, (newVal) => {
    searchQuery.value = newVal;
});

const doSearch = () => {
    router.push({ path: SEARCH_PATH, query: { q: searchQuery.value } });
};

searchQuery.value = searchParam.value;
</script>

<style lang="less">
.search-form {
    position: relative;
    line-height: 0;

    @media @q-xl-min {
        flex: 1 1 100%;
        min-width: 10rem;
    }

    &.search-form--largest {
        .dn-input.dn-input--rounded {
            height: var(--spacing-section-lg);

            .dn-input__field-body {
                display: flex;
                align-items: center;
            }
        }
    }
}

// Modifiers
.search-form--navigation {

    @media @q-lg-min {
        max-width: 13rem;
    }

    @media @q-xxl-min {
        max-width: 21.875rem;
    }

    .dn-input.dn-input--rounded-secondary {
        background-color: var(--color-lightest) !important;  /* stylelint-disable-line declaration-no-important */

        @media @q-xl-min {
            background-color: var(--color-secondary-light) !important;  /* stylelint-disable-line declaration-no-important */
        }

        .dn-input__field {
            font-size: var(--font-size-sm);
            display: none;

            @media @q-xl-min {
                display: block;
            }
        }

        .dn-input__after {
            width: 1.4rem;

            @media @q-xl-min {
                width: 3.5rem;
            }
        }
    }
}
</style>
